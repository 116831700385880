import React from 'react';
import './offers.css';

const Offers = () => {
  return (
    <section className='offers section' id='offers'>
      <h2 className='section__title'>Offers</h2>
      <h3 className='section__subtitle'>Our latest discounts and offers</h3>
      <div className='offers__items container grid'>
        <div className='offers__item'>
          <img src={require('../../assets/offers/offer1.jpg')} alt='offer' title='offer' className='offers__img' width="400" height="400" />
          <div>
            <div className='offers__item-title'>
              <h4>20% Off all Laser Treatments</h4>
              <span>This offer is valid from 25.02.2024 till 25.09.2024</span>
            </div>
            <a className='offers__items_link' href='#contact'>Book Now</a>
          </div>
        </div>
        <div className='offers__item'>
        <img src={require('../../assets/offers/offer3.jpg')} alt='offer' title='offer' className='offers__img' width="400" height="400" />
          <div>
            <div className='offers__item-title'>
              <h4>Book Brazilian/Hollywood & half Leg wax and you'll get free under arm wax</h4>
            </div>
            <a className='offers__items_link' href='#contact'>Book Now</a>
          </div>
        </div>
        <div className='offers__item'>
        <img src={require('../../assets/offers/offer2.png')} alt='offer' title='offer' className='offers__img' width="400" height="400"/>
          <div>
            <div className='offers__item-title'>
              <h4>Book Brazilian/Hollywood & Full Leg wax and you'll get free eyebrow wax & tint</h4>
            </div>
            <a className='offers__items_link' href='#contact'>Book Now</a>
          </div>
        </div>
        <div className='offers__item'>
        <img src={require('../../assets/offers/offer4.jpg')} alt='offer' title='offer' className='offers__img' width="400" height="400"/>
          <div>
            <div className='offers__item-title'>
              <h4>Brazilian/Hollywood wax for only €30</h4>
            </div>
            <a className='offers__items_link' href='#contact'>Book Now</a>
          </div>
        </div>        
      </div>
    </section>
  )
}

export default Offers
export const Data = [
    {
        id: 1,
        title: 'Mary F, via Groupon',
        description: 
        'Gloria was amazing she was very friendly and explained lots of things about the massage.'
    },
    {
        id: 2,
        title: 'Jacinta Eydan, via Twitter',
        description: 
        'Always receive an excellent service from the staff and I would highly recommend Trend to anybody living locally or anybody planning a stay in Cork.'
    },
    {
        id: 3,
        title: 'P Lucía Santana A, via Facebook',
        description: 
        'Since last year I am going to Trend for a therapy massages. After try another place and do not have any improvement, I find Trend really good. All the pain that I was feeling is gone. The staff always do her best to help you.'
    },
    {
        id: 4,
        title: 'Enddie AO, via Facebook',
        description: 
        'Went in today and Gloria was very nice filled out medical form and headed straight for the full body massage with the voucher from Groupon I purchased last year. I have never have/had professional massage before and it was my first and would say I was impress. I would be coming back here again.Thanks Gloria and see you soon!'
    },
    {
        id: 5,
        title: 'Kristina P, via Facebook',
        description: 
        'I have got vouchers from my fiancé which was very sweet of him. I really enjoyed my massage lady was very good but rough which was good for me as my back has been very stiff lately. I will be back. Thank you '
    }
];
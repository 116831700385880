import React from 'react';
import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: require('../../assets/slider/slider1.jpg'),
    thumbnail: require('../../assets/slider/slider1.jpg'),
    originalAlt: "Homepage slider image",
    originalTitle: "Homepage slider image",
    thumbnailAlt: "Homepage slider image",
    thumbnailTitle: "Homepage slider image",
    originalHeight: 850,
    originalWidth: 1200,
    thumbnailHeight: 52,
    thumbnailWidth:92
  },
  {
    original: require('../../assets/slider/slider2.jpg'),
    thumbnail: require('../../assets/slider/slider2.jpg'),
    originalAlt: "Homepage slider image",
    originalTitle: "Homepage slider image",
    thumbnailAlt: "Homepage slider image",
    thumbnailTitle: "Homepage slider image",
    originalHeight: 850,
    originalWidth: 1200,
    thumbnailHeight: 52,
    thumbnailWidth:92
  },
  {
    original: require('../../assets/slider/slider3.jpg'),
    thumbnail: require('../../assets/slider/slider3.jpg'),
    originalAlt: "Homepage slider image",
    originalTitle: "Homepage slider image",
    thumbnailAlt: "Homepage slider image",
    thumbnailTitle: "Homepage slider image",
    originalHeight: 850,
    originalWidth: 1200,
    thumbnailHeight: 52,
    thumbnailWidth:92
  },
  {
    original: require('../../assets/slider/slider4.jpg'),
    thumbnail: require('../../assets/slider/slider4.jpg'),
    originalAlt: "Homepage slider image",
    originalTitle: "Homepage slider image",
    thumbnailAlt: "Homepage slider image",
    thumbnailTitle: "Homepage slider image",
    originalHeight: 850,
    originalWidth: 1200,
    thumbnailHeight: 52,
    thumbnailWidth:92
  },
  {
    original: require('../../assets/slider/slider5.jpg'),
    thumbnail: require('../../assets/slider/slider5.jpg'),
    originalAlt: "Homepage slider image",
    originalTitle: "Homepage slider image",
    thumbnailAlt: "Homepage slider image",
    thumbnailTitle: "Homepage slider image",
    originalHeight: 850,
    originalWidth: 1200,
    thumbnailHeight: 52,
    thumbnailWidth:92
  },
  {
    original: require('../../assets/slider/slider6.jpg'),
    thumbnail: require('../../assets/slider/slider6.jpg'),
    originalAlt: "Homepage slider image",
    originalTitle: "Homepage slider image",
    thumbnailAlt: "Homepage slider image",
    thumbnailTitle: "Homepage slider image",
    originalHeight: 850,
    originalWidth: 1200,
    thumbnailHeight: 52,
    thumbnailWidth:92
  },
];

const Slider = () => {
  return (
    <section className='home section homepage_slider' id='home'>
        <ImageGallery items={images} autoPlay/>
    </section>
  )
}

export default Slider
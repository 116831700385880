import React from "react";
import {RiDoubleQuotesL} from 'react-icons/ri';
import {Data} from './Data';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const Testimonials = () => {

  return (
    <section className='testimonials section' id='testimonials'>
      <h2 className='section__title'>Testimonials</h2>
      <h3 className='section__subtitle'>What our clients say about us</h3>
      <RiDoubleQuotesL className='testimonials__icon'/>
      <Swiper className='testimonials__container  container grid'
        loop={true}
        grabCursor={true}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        >
        {Data.map(({id, title, description}) => {
          return (
            <SwiperSlide className='testimonials__card' key={id}>
              <h3 className='testimonials__name'>{title}</h3>
              <p className='testimonials__description'>{description}</p>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  )
}

export default Testimonials